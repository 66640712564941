import axiosInstance from "../axiosInstance";
import {toast} from "react-toastify";
import {t} from "i18next";

const shouldHandleError = (error) => {
    let reactorException = error.response?.data
    console.log(reactorException)
    if (reactorException?.code === 'R6') {
        return false
    }
    return true
}

const baseApiService = {
    get: async (path, params = {}) => {
        try {
            console.log(`GET ${path}`)
            console.log(params)
            const response = await axiosInstance.get(`${path}`, {params});
            //TODO: Remove debug logging
            console.log(`GET ${path}, Response:`);
            console.log(JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            if (shouldHandleError(error)) {
                toast.error(t(error.response.data ? error.response.data.code : 'general_error'))
            }
            throw error;
        }
    },

    post: async (path, data = {}) => {
        try {
            console.log(`POST ${path}`)
            console.log(JSON.stringify(data))
            const response = await axiosInstance.post(`${path}`, data);
            //TODO: Remove debug logging
            console.log(`POST ${path}, Response:`);
            console.log(JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.log(error)
            if (shouldHandleError(error)) {
                toast.error(t(error.response.data ? error.response.data.code : 'general_error'))
            }
            throw error;
        }
    },

    patch: async (path, data = {}) => {
        try {
            console.log(`PATCH ${path}`)
            console.log(JSON.stringify(data))
            const response = await axiosInstance.patch(`${path}`, data);
            //TODO: Remove debug logging
            console.log(`PATCH ${path}, Response:`);
            console.log(JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.log(error)
            if (shouldHandleError(error)) {
                toast.error(t(error.response.data ? error.response.data.code : 'general_error'));
            }
            throw error;
        }
    },
    delete: async (path) => {
        try {
            console.log(`DELETE ${path}`)
            const response = await axiosInstance.delete(`${path}`);
            console.log(`DELETE ${path}, Response:`);
            console.log(JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            console.log(error)
            if (shouldHandleError(error)) {
                toast.error(t(error.response.data ? error.response.data.code : 'general_error'));
            }
            throw error;
        }
    },
};

export default baseApiService;