import React, {useEffect, useState} from 'react';
import NavBar from "../../../components/common/NavigationBar/NavBar";
import styled from "styled-components";
import Report from "../../../components/features/Report/Report";
import Grid from "@mui/material/Grid";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import profileService from "../../../services/api/profile/profileService";
import {t} from "i18next";
import {
    Avatar,
    createTheme,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tabs,
    ThemeProvider
} from "@mui/material";
import colors from "../../../assets/styles/colors";
import {useParams} from "react-router-dom";
import ForeignProfileHeader from "../../../components/features/Profile/ForeignProfileHeader";
import Container from "@mui/material/Container";
import {Bounce, toast} from "react-toastify";
import {Email, Phone, Place} from "@mui/icons-material";
import {parsePhoneNumber} from "libphonenumber-js";
import NoResults from "../../../components/features/NoResults/NoResults";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import Unknown from "../../../assets/images/unknown.svg";
import ProfileListing from "../../../components/features/Listing/ProfileListing";
import {logShelterPreview} from "../../../services/firebase/analyticService";

const ProfileHeaderWrapper = styled.section`
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media (max-width: 599px) {
        padding-top: 1rem;
    }
`

const Content = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
`

const tabsTheme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        color: `${colors.textColor}`,
                        fontWeight: '700',
                        fontSize: '1rem',
                    },
                },
            },
        },
    }
});

const AnimatedWrapper = styled.div`
    padding-bottom: 6rem;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const DonationHeader = styled.p`
    font-weight: 600;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
`

const AboutHeader = styled.h5`
    font-weight: 600;
    color: ${colors.textColor};
    margin: 0 0 1rem;
`

const DonationValue = styled.p`
    font-weight: 400;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
    background-color: ${colors.secondaryBackground};
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 12px 100px 12px 12px;


    &::after {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(colors.textColor)}" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.3;
        transition: opacity 0.2s;
        color: ${colors.textColor};
    }


    &:hover::after {
        opacity: 1;
    }

`;

const ContactText = styled.p`
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0;
    text-align: left;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
`

const MyProfilePage = () => {
    const {profileId} = useParams();

    const [reports, setReports] = useState(null);
    const [listings, setListings] = useState(null);
    const [section, setSection] = React.useState(0);

    const [profile, setProfile] = useState(null);
    const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);

    const [openDonationDialog, setOpenDonationDialog] = React.useState(false);
    const [openVolunteeringDialog, setOpenVolunteering] = React.useState(false);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const publicProfile = await profileService.getProfile(profileId);
                logShelterPreview(profileId)
                setProfile(publicProfile);
                setReports(publicProfile.reports);
                setListings(publicProfile.listings);

                setIsLoading(false);
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };


        fetchProfileData();
    }, [profileId, setIsLoading]);

    if (showLoadingUI) {
        return <LokiLoader/>
    }

    if (isLoading) {
        return (<div/>)
    }

    const handleSectionChange = (event, newValue) => {
        setSection(newValue);
    };

    const handleDonationDialogClose = () => {
        setOpenDonationDialog(false);
    };

    const handleVolunteeringDialogClose = () => {
        setOpenVolunteering(false);
    };

    const onVolunteeringClick = () => {
        setOpenVolunteering(true)
    }

    const onDonateClick = () => {
        if (profile.donationDetails.type === 'IBAN') {
            setOpenDonationDialog(true)
        }

        if (profile.donationDetails.type === 'Web') {
            window.open(profile.donationDetails.websiteUrl, '_blank');
        }
    }

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast(t('copied_to_clipboard'), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                theme: "light",
                transition: Bounce,
            })

        } catch (e) {
            console.log(e)
            toast.error(t(e.code || 'general_error'))
        }
    }

    const getFormattedPhoneNumber = () => {
        const phoneNumber = parsePhoneNumber(profile.location.contactNumber)
        return phoneNumber.formatInternational()
    }

    function formatIBAN(iban) {
        if (!iban) {
            return;
        }

        const cleanIban = iban.replace(/\s+/g, '');
        return cleanIban.replace(/(.{4})/g, '$1 ');
    }

    function ListingsSection({listings}) {
        return listings.length > 0 ? (
            listings.map((listing) => (
                <Grid key={listing.id} item xs={12} sm={4} md={3} lg={3}>
                    <ProfileListing listing={listing}/>
                </Grid>
            ))
        ) : (
            <Grid item xs={12}>
                <NoResults title={t('shelter_no_listings_title')}/>
            </Grid>
        );
    }

    function ReportsSection({reports}) {
        return reports.length > 0 ? (
            reports.map((report) => (
                <Grid key={report.id} item xs={12} sm={4} md={3} lg={3}>
                    <Report thumbnailUrl={report.thumbnails[0]} reportId={report.id}/>
                </Grid>
            ))
        ) : (
            <Grid item xs={12}>
                <NoResults title={t('shelter_no_reports_title')}/>
            </Grid>
        );
    }

    function AboutSection({profile}) {
        return (
            <div style={{marginTop: '2rem', paddingLeft: '1rem'}}>
                <AboutHeader>{t('title_about_shelter').replace('{shelterDisplayName}', profile.displayName)}</AboutHeader>
                <div style={{marginLeft: '8px'}}>
                    <Item>
                        <Avatar>
                            <Phone/>
                        </Avatar>
                        <ContactText>{getFormattedPhoneNumber()}</ContactText>
                    </Item>

                    <Item style={{marginTop: '1rem'}}>
                        <Avatar>
                            <Email/>
                        </Avatar>
                        <ContactText>{profile.location.contactEmail}</ContactText>
                    </Item>

                    <Item style={{marginTop: '1rem'}}>
                        <Avatar>
                            <Place/>
                        </Avatar>
                        <ContactText>{`${profile.location.street}, ${profile.location.city}, ${profile.location.country}`}</ContactText>
                    </Item>
                </div>
            </div>
        );
    }

    function ContentGrid({section, listings, reports, profile}) {
        return (
            <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16, paddingTop: 16}}>
                {section === 0 && <ListingsSection listings={listings}/>}
                {section === 1 && <ReportsSection reports={reports}/>}
                {section === 2 && <AboutSection profile={profile}/>}
            </Grid>
        );
    }

    return (
        <AnimatedWrapper>
            <NavBar/>
            <Container maxWidth="md">
                <ProfileHeaderWrapper>
                    <ForeignProfileHeader avatar={profile ? profile.avatarUrl : Unknown}
                                          displayName={profile.displayName}
                                          bio={profile.bio} onDonateClick={onDonateClick}
                                          onVolunteeringClick={onVolunteeringClick}/>
                </ProfileHeaderWrapper>
                <ThemeProvider theme={tabsTheme}>
                    <Tabs
                        value={section}
                        onChange={handleSectionChange}
                        TabIndicatorProps={{
                            style: {
                                width: '10%',
                                marginLeft: '8px',
                                background: `${colors.secondaryColor}`
                            }
                        }}
                    >
                        <Tab
                            label={t('section_listings')}/>
                        <Tab
                            label={t('section_reports')}/>
                        <Tab
                            label={t('section_about')}/>
                    </Tabs>
                </ThemeProvider>
                <Content>
                    <Grid container spacing={2} style={{paddingLeft: 16, paddingRight: 16}}>
                        <ContentGrid section={section} reports={reports} listings={listings} profile={profile}/>
                    </Grid>
                </Content>

                <Dialog
                    open={openDonationDialog}
                    onClose={handleDonationDialogClose}>
                    <DialogTitle>{t('donation_details')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div style={{display: 'flex', flexDirection: "column", paddingBottom: '16px'}}>
                                <DonationHeader>{t('recipient')}</DonationHeader>
                                <DonationValue alt="Copy"
                                               onClick={() => copyToClipboard(profile.donationDetails.recipient)}>{profile.donationDetails.recipient}</DonationValue>

                                <DonationHeader style={{marginTop: '8px'}}>{t('iban')}</DonationHeader>
                                <DonationValue alt="Copy"
                                               onClick={() => copyToClipboard(profile.donationDetails.iban)}>{formatIBAN(profile.donationDetails.iban)}</DonationValue>

                                {profile.donationDetails.description ? (
                                    <>
                                        <DonationHeader style={{marginTop: '8px'}}>{t('description')}</DonationHeader>
                                        <DonationValue alt="Copy"
                                                       onClick={() => copyToClipboard(profile.donationDetails.description)}>{profile.donationDetails.description}</DonationValue>
                                    </>
                                ) : (<div/>)}

                                <DonationHeader style={{marginTop: '8px'}}>{t('bank')}</DonationHeader>
                                <DonationValue alt="Copy"
                                               onClick={() => copyToClipboard(profile.donationDetails.bankName)}>{profile.donationDetails.bankName}</DonationValue>

                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openVolunteeringDialog}
                    onClose={handleVolunteeringDialogClose}>
                    <DialogTitle>{t('volunteering_details').replace('{shelterDisplayName}', profile.displayName)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p>{profile.volunteeringDetails}</p>
                            <br/>
                            <Item>
                                <Avatar>
                                    <Phone/>
                                </Avatar>
                                <ContactText>{getFormattedPhoneNumber()}</ContactText>
                            </Item>

                            <Item style={{marginTop: '1rem'}}>
                                <Avatar>
                                    <Email/>
                                </Avatar>
                                <ContactText>{profile.location.contactEmail}</ContactText>
                            </Item>

                            <Item style={{marginTop: '1rem'}}>
                                <Avatar>
                                    <Place/>
                                </Avatar>
                                <ContactText>{`${profile.location.street}, ${profile.location.city}, ${profile.location.country}`}</ContactText>
                            </Item>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Container>
        </AnimatedWrapper>
    );
};

export default MyProfilePage;