import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import listingService from "../../services/api/listing/listingService";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import Logo from "../../assets/images/logo-high-res.png"
import styled from "styled-components";
import {Avatar, Chip, Divider, Fade} from "@mui/material";
import {t} from "i18next";
import {Email, Phone, Place} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {parsePhoneNumber} from "libphonenumber-js";
import LokiSecondaryOutlinedButton from "../../components/common/Buttons/LokiSecondaryOutlinedButton";
import {toast} from "react-toastify";

const SectionHeader = styled.h4`
    font-weight: 600;
`

const SectionText = styled.h6`
    font-weight: 400;
    margin: 0;
    text-align: center;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
`

const ContactText = styled.p`
    font-weight: 400;
    margin: 0;
    text-align: center;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    justify-content: flex-start;
`
const ProfilePicture = styled.img`
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;

    @media (max-width: 599px) {
        width: 50%;
    }
`

const ReservationSuccessPage = () => {
    let navigate = useNavigate()

    const {listingId, reservationNumber} = useParams();
    const [listing, setListing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchListing = async () => {
            try {
                const data = await listingService.getListing(listingId);
                setListing(data);
                setIsLoading(false);
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchListing();
    }, [listingId]);

    if (isLoading) {
        return <LokiLoader/>
    }

    const onDoneClick = () => {
        navigate("/", {replace: true})
    }

    const onLogoClick = () => {
        navigate("/", {replace: true})
    }

    const getFormattedPhoneNumber = () => {
        const phoneNumber = parsePhoneNumber(listing.profile.location.contactNumber)
        return phoneNumber.formatInternational()
    }

    const {pet} = listing || {};
    return (
        <>
            <Container maxWidth="md" sx={{mb: 6, mt: 6}}>
                <Fade in={true} timeout={300}>
                    <Paper sx={{p: 4, borderRadius: '12px', boxShadow: 12}}>
                        <img loading="eager" alt="Loki logo" src={Logo} width="64px" style={{cursor: 'pointer'}}
                             onClick={onLogoClick}/>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <SectionHeader>{t('reservation_success_title')}</SectionHeader>
                            <SectionText>{t('reservation_success_subtitle')}</SectionText>
                            <Divider flexItem sx={{mt: 4, mb: 1, ml: 6, mr: 6}}>
                                <Chip label={t('reservation_success_whats_next')} size="large" sx={{fontSize: '1rem'}}/>
                            </Divider>
                            <Column>
                                <SectionText
                                    style={{marginTop: '2rem'}}>
                                    {t('reservation_success_whats_next_description')
                                        .replace('{petName}', pet.name)
                                        .replace('{shelterDisplayName}', listing.profile.displayName)}
                                </SectionText>
                                <SectionText
                                    style={{marginTop: '0.5rem'}}>{t('reservation_success_whats_next_reservation_number')}</SectionText>
                                <br/>
                                <h5><b>{reservationNumber}</b></h5>
                            </Column>
                            <Divider flexItem sx={{mt: 4, mb: 1, ml: 6, mr: 6}}>
                                <Chip label={t('shelter')} size="large" sx={{fontSize: '1rem'}}/>
                            </Divider>

                            <Grid container>
                                <Grid xs={12} sm={4}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '1rem'
                                    }}>
                                        <ProfilePicture src={listing.profile.avatarUrl}/>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={8}>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        width: '100%',
                                        padding: '1rem'
                                    }}>
                                        <ContactText style={{
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>{listing.profile.legalDisplayName}</ContactText>


                                        <Item style={{marginTop: '1rem'}}>
                                            <Avatar>
                                                <Phone/>
                                            </Avatar>
                                            <ContactText>{getFormattedPhoneNumber()}</ContactText>
                                        </Item>

                                        <Item style={{marginTop: '1rem'}}>
                                            <Avatar>
                                                <Email/>
                                            </Avatar>
                                            <ContactText>{listing.profile.location.contactEmail}</ContactText>
                                        </Item>

                                        <Item style={{marginTop: '1rem'}}>
                                            <Avatar>
                                                <Place/>
                                            </Avatar>
                                            <ContactText>{`${listing.profile.location.street}, ${listing.profile.location.city}, ${listing.profile.location.country}`}</ContactText>
                                        </Item>
                                    </div>
                                </Grid>
                            </Grid>

                            <LokiSecondaryOutlinedButton
                                style={{marginTop: '2rem'}}
                                onClick={onDoneClick}>{t('close')}</LokiSecondaryOutlinedButton>
                        </div>
                    </Paper>
                </Fade>
            </Container>
        </>
    )
        ;
};

export default ReservationSuccessPage;