import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Gallery from "../../../components/features/Details/Gallery";
import MobileGallery from "../../../components/features/Details/MobileGallery";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import ReportDescription from "../../../components/features/ReportDetails/ReportDescription";
import {t} from "i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade} from "@mui/material";
import reportService from "../../../services/api/report/reportService";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";
import Container from "@mui/material/Container";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import colors from "../../../assets/styles/colors";
import {logMissingReport} from "../../../services/firebase/analyticService";

const ResponsiveWrapper = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 70px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: unset;
        margin-bottom: 100px;
    }
`

const ReportDetailsPage = () => {
        let navigate = useNavigate()
        const {isLoading: isAuthLoading, isAuthenticated} = useAuth();
        const {reportId} = useParams();
        const [report, setReport] = useState(null);
        const [isMyReport, setIsMyReport] = useState(false);
        const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);
        const [open, setOpen] = React.useState(false);
        const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

        useEffect(() => {
            const fetchReport = async () => {
                try {
                    const response = await reportService.getReport(reportId);
                    logMissingReport(reportId)
                    setReport(response);
                    setIsLoading(false);
                } catch (err) {
                    console.log(err)
                    toast.error(t(err.code || 'general_error'))
                    setIsLoading(false);
                }
            };

            fetchReport();
        }, [reportId, setIsLoading]);

        useEffect(() => {
            const checkReportOwnership = async () => {
                if (!isAuthenticated()) {
                    return;
                }
                if (isAuthLoading()) {
                    return;
                }

                //TODO: Not good, separate endpoint needed
                const myReports = await reportService.getMyReports(undefined, 1, 50);
                const isMyReport = myReports.content.some((report) => report.id.toString() === reportId);
                setIsMyReport(isMyReport)
            }

            checkReportOwnership()
        }, [isAuthLoading, isAuthenticated, reportId]);

        if (showLoadingUI) {
            return <LokiLoader/>
        }

        if (isLoading) {
            return (<div/>);
        }

        const handleClose = () => {
            setOpen(false);
        };

        const onContactClick = () => {
            setOpen(true);
        }

        const onDeleteClick = () => {
            setOpenDeleteDialog(true)
        }

        const handleDeleteDialogClose = () => {
            setOpenDeleteDialog(false)
        }

        const deleteReport = async () => {
            setIsLoading(true)
            try {
                await reportService.deleteReport(reportId)
                toast.info(t('report_delete_success'))
                navigate('/profile', {replace: true})
            } catch (error) {
                console.log(error)
                toast.error(t(error.code || 'general_error'))
            }

            setIsLoading(false);
        }

        const heading = report ? `${t(report.breed.localizationKey)} • ${t(report.breed.species.localizationKey)}` : ""

        return (
            <>
                <NavBar/>
                <Fade in={true} timeout={300}>
                    <Container maxWidth="lg" sx={{mt: 6}}>
                        <ResponsiveWrapper>
                            <Gallery
                                images={report.images}
                                thumbnails={report.thumbnails}
                            />
                            <MobileGallery
                                images={report.thumbnails}
                            />
                            <ReportDescription
                                title={t(report.type)}
                                description={report.description}
                                timestamp={report.timestamp}
                                heading={heading}
                                onDeleteClick={onDeleteClick}
                                onContactClick={onContactClick}
                                isMyReport={isMyReport}
                            />
                        </ResponsiveWrapper>
                    </Container>
                </Fade>
                <Dialog
                    open={open}
                    onClose={handleClose}>
                    <DialogTitle>{t('contact_details')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{paddingRight: 16}}>
                            {report.contactDetails}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{textTransform: 'none'}} onClick={handleClose}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDeleteDialog}
                        onClose={handleDeleteDialogClose}>
                    <DialogTitle>{t('delete_report')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('delete_confirmation_message')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{textTransform: 'none'}} onClick={handleDeleteDialogClose}>{t('cancel')}</Button>
                        <Button sx={{textTransform: 'none', color: colors.destructive}} onClick={deleteReport}
                                autoFocus>
                            {t('delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
;

export default ReportDetailsPage;