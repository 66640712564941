import axios from 'axios';
import {getCurrentUserIdToken, logout} from "./firebase/firebaseService";

const API_BASE_URL = process.env.REACT_APP_REACTOR_BASE_URL;
const HEADER_AUTHORIZATION = 'Authorization';
const HEADER_APPLICATION_FLAVOR = 'Application-Flavor';
const APPLICATION_FLAVOR = process.env.REACT_APP_APPLICATION_FLAVOR;

const logoutUser = async () => {
    await logout();
};

const axiosInstance = axios.create({baseURL: API_BASE_URL});

const getAuthorizationHeaderValue = async () => {
    try {
        const idToken = await getCurrentUserIdToken();
        return `Bearer ${idToken}`;
    } catch (error) {
        console.error('Failed to get ID token', error);
        await logoutUser(); // Ensure logout if token cannot be retrieved
        return undefined;
    }
};

axiosInstance.interceptors.request.use(async (config) => {
    const authorizationHeaderValue = await getAuthorizationHeaderValue();
    if (authorizationHeaderValue) {
        config.headers[HEADER_AUTHORIZATION] = authorizationHeaderValue;
    }
    config.headers[HEADER_APPLICATION_FLAVOR] = APPLICATION_FLAVOR;
    config.headers["Content-Type"] = 'application/json';
    return config;
}, error => Promise.reject(error));

axiosInstance.interceptors.response.use(response => response, async (error) => {
    if (error.response && error.response.status === 401) {
        // Logout the user for any 401 Unauthorized response
        await logoutUser();
    }

    return Promise.reject(error);
});

export default axiosInstance;