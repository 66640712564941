import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import LokiLoader from "../../components/common/Loader/LokiLoader";
import Logo from "../../assets/images/logo-high-res.png"
import styled from "styled-components";
import {
    Avatar,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fade
} from "@mui/material";
import {t} from "i18next";
import {Email, Phone, Place} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import {parsePhoneNumber} from "libphonenumber-js";
import LokiSecondaryOutlinedButton from "../../components/common/Buttons/LokiSecondaryOutlinedButton";
import reservationService from "../../services/api/reservation/reservationService";
import BackLink from "../../components/common/Buttons/BackLink";
import formatAge from "../../utils/UnitUtils";
import colors from "../../assets/styles/colors";
import {toast} from "react-toastify";
import LokiPrimaryButton from "../../components/common/Buttons/LokiPrimary";

const SectionHeader = styled.h4`
    font-weight: 600;
`

const SectionText = styled.h6`
    font-weight: 400;
    margin: 0;
    text-align: center;
`

const ContactText = styled.p`
    font-weight: 400;
    margin: 0;
    text-align: center;
`

const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 1rem;
    margin: 8px 0 0;
    color: ${colors.textColor};
    text-align: left;
`


const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
`
const ProfilePicture = styled.img`
    border-radius: 8px;
    width: 80%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;

    @media (max-width: 599px) {
        width: 50%;
    }
`

const ReservationDetailsPage = () => {
    let navigate = useNavigate()

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const {reservationNumber} = useParams();
    const [reservation, setReservation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchReservation = async () => {
            try {
                const data = await reservationService.getReservation(reservationNumber);
                setReservation(data);
                setIsLoading(false);
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchReservation();
    }, [reservationNumber]);

    if (isLoading) {
        return <LokiLoader/>
    }

    const onLogoClick = () => {
        navigate("/", {replace: true})
    }

    const getFormattedPhoneNumber = () => {
        const phoneNumber = parsePhoneNumber(listing.profile.location.contactNumber)
        return phoneNumber.formatInternational()

    }

    const handleCloseClick = () => {
        navigate('/profile', {replace: true})
    }

    const handleDeleteButtonClick = async () => {
        setOpenDeleteDialog(true)
    }

    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }

    const cancelReservation = async () => {
        try {
            setIsLoading(true)
            await reservationService.cancel(reservationNumber)
            toast.info(t('reservation_cancel_success'))
            setIsLoading(false);
            navigate('/profile')
        } catch (e) {
            console.log(e)
            toast.error(t(e.code || 'general_error'))
            setIsLoading(false);
        }
    }

    const {listing} = reservation
    const {pet} = listing
    const heading = pet ? `${formatAge(pet.age)} • ${t(pet.breed.localizationKey)} • ${t(pet.breed.species.localizationKey)}` : ""

    return (
        <>
            <Container maxWidth="md" sx={{mb: 6, mt: 6}}>
                <Fade in={true} timeout={300}>
                    <Paper elevation={1} sx={{borderRadius: 4, p: 4}}>
                        <div style={{position: "relative", width: '100%'}}>
                            <div style={{paddingTop: '1rem'}}>
                                <BackLink/>
                            </div>
                            <img src={Logo} width="64px"
                                 loading="eager"
                                 alt="Loki logo"
                                 style={{cursor: 'pointer', position: 'absolute', top: 0, right: 0}}
                                 onClick={onLogoClick}/>
                        </div>
                        <br/>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <SectionHeader>{reservationNumber}</SectionHeader>
                            <SectionText>{t('reservation_details')}</SectionText>
                            <Divider flexItem sx={{mt: 3, mb: 1, ml: 6, mr: 6}}>
                                <Chip label={t('pet')} size="large" sx={{fontSize: '1rem'}}/>
                            </Divider>

                            <Grid container>
                                <Grid xs={12} sm={4}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '1rem'
                                    }}>
                                        <ProfilePicture src={listing.pet.thumbnails[0]}/>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={8}>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        width: '100%',
                                        padding: '1rem'
                                    }}>
                                        <SectionText style={{
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>{listing.pet.name}</SectionText>
                                        <ContactText style={{color: `${colors.textColor}`}}>{heading}</ContactText>
                                        <DescriptionText>{pet.notes}</DescriptionText>
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider flexItem sx={{mt: 3, mb: 1, ml: 6, mr: 6}}>
                                <Chip label={t('shelter')} size="large" sx={{fontSize: '1rem'}}/>
                            </Divider>

                            <Grid container>
                                <Grid xs={12} sm={4}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '1rem'
                                    }}>
                                        <ProfilePicture src={listing.profile.avatarUrl}/>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={8}>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                        width: '100%',
                                        padding: '1rem'
                                    }}>
                                        <ContactText style={{
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>{listing.profile.legalDisplayName}</ContactText>
                                        <br/>

                                        <Item>
                                            <Avatar>
                                                <Phone/>
                                            </Avatar>
                                            <ContactText>{getFormattedPhoneNumber()}</ContactText>
                                        </Item>

                                        <Item style={{marginTop: '1rem'}}>
                                            <Avatar>
                                                <Email/>
                                            </Avatar>
                                            <ContactText>{listing.profile.location.contactEmail}</ContactText>
                                        </Item>

                                        <Item style={{marginTop: '1rem'}}>
                                            <Avatar>
                                                <Place/>
                                            </Avatar>
                                            <ContactText>{`${listing.profile.location.street}, ${listing.profile.location.city}, ${listing.profile.location.country}`}</ContactText>
                                        </Item>
                                    </div>
                                </Grid>
                            </Grid>

                            <div style={{marginTop: '2rem'}}/>

                            {reservation.status === 'Submitted' ?
                                <LokiSecondaryOutlinedButton
                                    onClick={handleDeleteButtonClick}>{t('cancel_reservation')}</LokiSecondaryOutlinedButton>
                                : <div/>
                            }
                            <LokiPrimaryButton
                                style={{marginTop: '1rem'}}
                                onClick={handleCloseClick}>{t('close')}</LokiPrimaryButton>
                        </div>
                    </Paper>
                </Fade>
                <Dialog open={openDeleteDialog}
                        onClose={handleDeleteDialogClose}>
                    <DialogTitle>{t('cancel_reservation_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('cancel_reservation_message')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{textTransform: 'none'}} onClick={handleDeleteDialogClose}>{t('close')}</Button>
                        <Button sx={{textTransform: 'none', color: colors.destructive}} onClick={cancelReservation}
                                autoFocus>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    )
        ;
};

export default ReservationDetailsPage;