import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import SearchResultsGroup from "../../../components/features/Search/SearchResultsGroup";
import searchService from "../../../services/api/search/searchService";
import NoResults from "../../../components/features/Search/ListItems/NoResults";
import {Fade, Pagination} from "@mui/material";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import miscService from "../../../services/api/misc/miscService";
import ShelterFilter from "../../../components/features/Search/ShelterFilter";
import SearchResultsSkeleton from "../../../components/features/Search/Skeleton/SearchResultsSkeleton";
import FilterSkeleton from "../../../components/features/Search/Skeleton/FilterSkeleton";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import {toast} from "react-toastify";
import {t} from "i18next";

//TODO: Display shelter city for each result
const RootWrapper = styled.div`
    padding-right: 30px;
    padding-left: 30px;
    @media screen and (max-width: 900px) {
        padding-right: 16px;
        padding-left: 16px;
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    padding-right: 16px;
    margin-top: 16px;

    @media screen and (max-width: 900px) {
        padding-right: 0px;
    }
`

const MarginWrapper = styled.div`
    margin-top: 1rem;
    width: 100%;
`


const ShelterSearchPage = () => {
    const [searchResults, setSearchResults] = useState(null);
    const {
        isLoading: isResultsLoading,
        showLoadingUI: showResultsLoadingUI,
        setIsLoading: setIsResultsLoading
    } = useDebouncedLoading(true);

    const {
        isLoading: isFilterLoading,
        showLoadingUI: showFilterLoadingUI,
        setIsLoading: setIsFilterLoading
    } = useDebouncedLoading(true);
    const [lastSearchFilter, setLastSearchFilter] = useState(null);
    const [cities, setCities] = useState(null);
    const [filter, setFilter] = useState({
        searchQuery: '',
        cityId: -1,
        sort: '',
        page: 1
    });
    const areFiltersDefault = () => {
        const defaultFilter = {
            searchQuery: '',
            cityId: -1,
            sort: '',
            page: 1
        };

        return JSON.stringify(filter) === JSON.stringify(defaultFilter);
    };

    /* eslint-disable react-hooks/exhaustive-deps */

    useEffect(() => {
        const initFetch = async () => {

            setIsFilterLoading(true);
            try {
                const cities = await miscService.getCities();
                setCities(cities)
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
            } finally {
                setIsFilterLoading(false);
            }
        };

        initFetch();
        performSearch()
    }, []);

    const filtersAreEqual = (filter1, filter2) => {
        return JSON.stringify(filter1) === JSON.stringify(filter2);
    };

    const performSearch = async (newFilter) => {
        const searchFilter = newFilter || filter;
        if (filtersAreEqual(searchFilter, lastSearchFilter)) {
            return;
        }

        setIsResultsLoading(true);
        try {
            const data = await searchService.searchShelters(searchFilter);
            setSearchResults(data);
            setLastSearchFilter(searchFilter)
        } catch (err) {
            console.log(err)
        } finally {
            setIsResultsLoading(false);
        }
    };

    const search = async () => {
        await performSearch()
    }

    const clearFilters = async () => {
        const newFilter = {
            searchQuery: '',
            cityId: -1,
            sort: '',
            page: 1
        };

        setFilter(newFilter)
        await performSearch(newFilter)
    }

    const handlePageChange = async (event, value) => {
        const newFilter = {...filter, page: value}
        setFilter(newFilter);
        await performSearch(newFilter)
    };

    return (
        <div>
            <NavBar/>
            <RootWrapper>
                <Grid container spacing={2} sx={{paddingBottom: "3rem"}}>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        {showFilterLoadingUI ?
                            <div style={{paddingTop: '1rem'}}>
                                <FilterSkeleton/>
                            </div>
                            :
                            isFilterLoading ? <div/> : <div style={{paddingTop: '1rem'}}>
                                <ShelterFilter cities={cities}
                                               filters={filter}
                                               setFilters={setFilter}
                                               onSearchClick={search}
                                               applyFilter={search}/>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={10}>
                        {showResultsLoadingUI ?
                            <Fade in={true} timeout={300}>
                                <ContentWrapper>
                                    <MarginWrapper>
                                        <SearchResultsSkeleton/>
                                        <br/>
                                        <SearchResultsSkeleton/>
                                    </MarginWrapper>
                                </ContentWrapper>
                            </Fade>
                            :
                            <ContentWrapper>
                                {!searchResults || searchResults.content.length === 0 ? (
                                    isResultsLoading ? <div/> :
                                        <NoResults onResetClick={clearFilters} hasFilters={!areFiltersDefault()}/>
                                ) : (
                                    <Fade in={true} timeout={300}>
                                        <div style={{width: '100%'}}>
                                            {searchResults.content && searchResults.content.length > 0 && (
                                                <MarginWrapper>
                                                    <SearchResultsGroup
                                                        key="shelters"
                                                        shelters={searchResults.content}/>
                                                </MarginWrapper>
                                            )}

                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                marginTop: '2rem'
                                            }}>
                                                <Pagination
                                                    count={searchResults.totalPages || 0}
                                                    page={filter.page}
                                                    onChange={handlePageChange}
                                                    variant="outlined"
                                                    shape="rounded"/>
                                            </div>
                                        </div>
                                    </Fade>
                                )}
                            </ContentWrapper>
                        }
                    </Grid>
                </Grid>
            </RootWrapper>
        </div>
    );
}

export default ShelterSearchPage;