import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Gallery from "../../../components/features/Details/Gallery";
import MobileGallery from "../../../components/features/Details/MobileGallery";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import ListingDescription from "../../../components/features/ListingDetails/ListingDescription";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import listingService from "../../../services/api/listing/listingService";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {t} from "i18next";
import formatAge from "../../../utils/UnitUtils";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import SmallProfileWidget from "../../../components/features/Profile/SmallProfileWidget";
import colors from "../../../assets/styles/colors";
import {Bounce, toast} from "react-toastify";
import {logListingPreview} from "../../../services/firebase/analyticService";

const ResponsiveWrapper = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    gap: 100px;
    margin: 40px auto 6rem;
    padding-right: 16px;
    padding-left: 16px;

    @media (max-width: 1200px) {
        gap: 30px;
    }
    @media (max-width: 1200px) {
        width: unset;
        flex-direction: column;
        align-items: center;
        gap: unset;
        margin: 0px 0px 3rem;
    }
`

const SectionHeader = styled.h3`
    font-weight: 500;
    font-size: 1.4rem;
    color: ${colors.textColor};
    padding-bottom: 10px;
`

const DonationHeader = styled.p`
    font-weight: 600;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
`

const DonationValue = styled.p`
    font-weight: 400;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
    background-color: ${colors.secondaryBackground};
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 12px 100px 12px 12px;


    &::after {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(colors.textColor)}" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.3;
        transition: opacity 0.2s;
        color: ${colors.textColor};
    }


    &:hover::after {
        opacity: 1;
    }

`;

const ListingDetailsPage = () => {
    let navigate = useNavigate()
    const {listingId} = useParams();
    const [listing, setListing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const fetchListing = async () => {
            try {
                logListingPreview(listingId)
                const data = await listingService.getListing(listingId);
                setListing(data);
                setIsLoading(false);
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchListing();
    }, [listingId]);

    if (isLoading) {
        return <LokiLoader/>
    }

    const onAdoptClick = () => {
        navigate(`reserve`)
    }
    const onDonateClick = () => {
        if (listing.profile.donationDetails.type === 'IBAN') {
            setOpen(true)
        }

        if (listing.profile.donationDetails.type === 'Web') {
            window.open(listing.profile.donationDetails.websiteUrl, '_blank');
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast(t('copied_to_clipboard'), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                theme: "light",
                transition: Bounce,
            })

        } catch (e) {
            console.log(e)
            toast.error(t(e.code || 'general_error'))
        }
    }

    function formatIBAN(iban) {
        if (!iban) {
            return;
        }

        const cleanIban = iban.replace(/\s+/g, '');
        return cleanIban.replace(/(.{4})/g, '$1 ');
    }


    const {pet} = listing || {};
    return (
        <>
            <NavBar/>
            <ResponsiveWrapper>
                <div style={{marginBottom: '1rem'}}>
                    <Gallery
                        images={pet.images}
                        thumbnails={pet.thumbnails}
                    />
                </div>
                <MobileGallery
                    images={pet.thumbnails}
                />
                <ListingDescription
                    title={listing.title}
                    description={listing.description}
                    pet={listing.pet}
                    onAdoptClick={onAdoptClick}
                    onDonateClick={onDonateClick}
                />
            </ResponsiveWrapper>

            <Container maxWidth="lg" sx={{mb: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <SectionHeader>
                    {t('listing_details_title').replaceAll("{petName}", pet.name)}
                < /SectionHeader>
                <div style={{width: '100%', marginRight: '36px !important'}}>
                    <TableContainer component={Paper} variant="outlined" sx={{borderRadius: '12px', padding: '1rem'}}>

                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>{t('property_species')}</b></TableCell>
                                    <TableCell align="left">{t(pet.breed.species.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_breed')}</b></TableCell>
                                    <TableCell align="left">{t(pet.breed.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_color')}</b></TableCell>
                                    <TableCell align="left">{t(pet.color)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_size')}</b></TableCell>
                                    <TableCell align="left">{t(pet.size.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_weight')}</b></TableCell>
                                    <TableCell align="left">{pet.weight ? `${pet.weight} kg` : t('unknown')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_gender')}</b></TableCell>
                                    <TableCell align="left">{t(pet.gender)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>{t('property_age')}</b></TableCell>
                                    <TableCell
                                        align="left">{pet.age !== undefined ? formatAge(pet.age) : t('unknown')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>

            <Container maxWidth="lg" sx={{mb: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <SectionHeader>
                    {t('listing_details_shelter')}
                < /SectionHeader>
                <Paper variant="outlined" sx={{borderRadius: '12px', padding: '1rem', ml: 1, mr: 1}}>
                    <SmallProfileWidget
                        profile={listing.profile}
                        onClick={() => navigate(`/profile/${listing.profile.userId}`)}/>
                </Paper>
            </Container>

            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>{t('donation_details')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{display: 'flex', flexDirection: "column", paddingBottom: '16px'}}>
                            <DonationHeader>{t('recipient')}</DonationHeader>
                            <DonationValue alt="Copy"
                                           onClick={() => copyToClipboard(listing.profile.donationDetails.recipient)}>{listing.profile.donationDetails.recipient}</DonationValue>

                            <DonationHeader style={{marginTop: '8px'}}>{t('iban')}</DonationHeader>
                            <DonationValue alt="Copy"
                                           onClick={() => copyToClipboard(listing.profile.donationDetails.iban)}>{formatIBAN(listing.profile.donationDetails.iban)}</DonationValue>

                            {listing.profile.donationDetails.description ? (
                                <>
                                    <DonationHeader style={{marginTop: '8px'}}>{t('description')}</DonationHeader>
                                    <DonationValue alt="Copy"
                                                   onClick={() => copyToClipboard(listing.profile.donationDetails.description)}>{listing.profile.donationDetails.description}</DonationValue>
                                </>
                            ) : (<div/>)}

                            <DonationHeader style={{marginTop: '8px'}}>{t('bank')}</DonationHeader>
                            <DonationValue alt="Copy"
                                           onClick={() => copyToClipboard(listing.profile.donationDetails.bankName)}>{listing.profile.donationDetails.bankName}</DonationValue>

                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
        ;
};

export default ListingDetailsPage;