import React from "react";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import formatAge from "../../../utils/UnitUtils";

const AdoptButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.primaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`
const DonateButton = styled.button`
    padding: 15px 40px;
    background-color: ${colors.secondaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

const DescriptionContent = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
    line-height: 1.7;
    @media (max-width: 768px) {
        line-height: 1.6;
        margin: 0;
    }
`

const TitleContent = styled.h1`
    color: ${colors.textColor};
    text-transform: capitalize;
    font-weight: 700;
    font-size: 50px;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 35px;
        margin: 0;
    }
`

const BreedContent = styled.p`
    color: ${colors.secondaryColor};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 0;
`

const ContentWrapper = styled.section`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 25px;
    @media (max-width: 768px) {
        padding: 10px;
        margin-top: 25px;
    }
`

const ListingDescription = ({title, description, pet, onAdoptClick, onDonateClick}) => {
    const heading = pet ? `${pet.name} • ${formatAge(pet.age)} • ${t(pet.breed.localizationKey)} • ${t(pet.breed.species.localizationKey)}` : ""

    return (
        <ContentWrapper>
            <BreedContent>{heading}</BreedContent>
            <TitleContent>{title}</TitleContent>
            <DescriptionContent>
                {description}
            </DescriptionContent>
            <ButtonsWrapper>
                <AdoptButton
                    onClick={onDonateClick}>
                    {t('button_donate')}
                </AdoptButton>
                <DonateButton
                    onClick={onAdoptClick}>
                    {t('button_adopt')}
                </DonateButton>
            </ButtonsWrapper>
        </ContentWrapper>
    );
};

export default ListingDescription;