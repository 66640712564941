import {useContext} from 'react';
import {AuthContext} from '../context/AuthContext';
import * as firebaseService from "../services/firebase/firebaseService";
import {
    auth,
    createUserWithEmailAndPassword,
    getUid,
    signInWithEmailAndPassword,
    signInWithGooglePopup
} from "../services/firebase/firebaseService";
import mixpanel from "mixpanel-browser";

const useAuth = () => {

    const {authState, setAuthState} = useContext(AuthContext);

    const isAuthenticated = () => {
        return authState.isAuthenticated;
    };

    const isLoading = () => {
        return authState.isLoading
    }
    const isVerifiedWithBackend = () => {
        return authState.isVerifiedWithBackend
    }

    const login = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password)
        mixpanel.identify(getUid())
    };

    const signInWithGoogle = async () => {
        const result = await signInWithGooglePopup()
        mixpanel.identify(getUid())
        return result
    }

    const register = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password)
        mixpanel.identify(getUid())
    };

    const setVerifiedWithBackend = async (verified) => {
        setAuthState(prevState => ({
            ...prevState,
            isVerifiedWithBackend: verified
        }));
    }

    const logout = async () => {
        try {
            mixpanel.identify(getUid())
            await firebaseService.logout();
            setAuthState({
                isAuthenticated: false,
                user: null,
                token: null,
                isLoading: false
            });
            console.log("USER LOGGED OUT")
        } catch (error) {
            throw error
        }
    };

    return {
        login,
        register,
        logout,
        isAuthenticated,
        isLoading,
        isVerifiedWithBackend,
        setVerifiedWithBackend,
        user: authState.user,
        signInWithGoogle
    };
};

export default useAuth;
