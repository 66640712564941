import React, {useEffect, useState} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import {t} from "i18next";
import {PhotoCamera} from "@mui/icons-material";
import mediaService from "../../../services/api/media/mediaService";
import {updateProfileAvatar} from "../../../services/firebase/firebaseService";
import {toast} from "react-toastify";
import profileService from "../../../services/api/profile/profileService";

const EditProfileModal = ({profile, open, setOpen, onSaved}) => {
    const [values, setValues] = useState({
        displayName: '',
        bio: '',
    });
    const [errors, setErrors] = useState({
        displayName: '',
    });
    const [avatar, setAvatar] = useState('');
    const [avatarFile, setAvatarFile] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);


    useEffect(() => {
        setValues((prevValues) => ({
            ...prevValues,
            displayName: profile.displayName || "",
            bio: profile.bio || ""
        }));

        setAvatar(profile.avatarUrl || '')

    }, [profile]);

    const handleClose = () => {
        //Reset values
        setErrors({
            displayName: '',
            bio: '',
        })
        setValues((prevValues) => ({
            ...prevValues,
            displayName: profile.displayName || "",
            bio: profile.bio || ""
        }));
        setAvatar(profile.avatarUrl || '')
        setAvatarFile(null)
        setLoading(false)
        setIsSaveButtonEnabled(false)
        setOpen(false);
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        const newValues = {...values, [name]: value};

        setValues(newValues);

        enableSaveButtonIfNeeded(newValues);
    };

    function enableSaveButtonIfNeeded(newValues) {
        const isEdited = newValues.displayName !== profile?.displayName ||
            newValues.bio !== profile?.bio ||
            avatarFile != null

        setIsSaveButtonEnabled(isEdited);
    }

    const handleSave = async () => {
        if (!validate()) {
            return
        }

        setLoading(true)
        try {
            const requestBody = profile;

            if (avatarFile) {
                const media = await mediaService.uploadFile(avatarFile, "profiles", true)
                await updateProfileAvatar(media.compressedUrl)

                requestBody.avatarUrl = media.compressedUrl
            }

            requestBody.displayName = values.displayName
            requestBody.bio = values.bio


            await profileService.editProfile(requestBody)

            toast.success(t('profile_edit_success'))
            setLoading(false);

            handleClose()
            
            setTimeout(() => {
                onSaved(requestBody)
            }, 1000);

        } catch (error) {
            console.log(error)
            toast.error(t(error.code || 'general_error'))
        }
    };

    const validate = () => {
        let tempErrors = {...errors};
        tempErrors.displayName = values.displayName ? '' : t('error_field_mandatory');

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setAvatarFile(file);
        const preview = URL.createObjectURL(file);
        setAvatar(preview);

        setIsSaveButtonEnabled(true);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>
                    {t('title_edit_profile')}
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                    }}>
                        <input
                            accept=".png, .jpg, .jpeg"
                            style={{display: 'none'}}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={handleAvatarChange}
                        />
                        {avatar && (
                            <img src={avatar} alt="Avatar"
                                 style={{
                                     width: '7rem',
                                     height: '7rem',
                                     marginTop: '20px',
                                     borderRadius: '360px',
                                     aspectRatio: 1,
                                     objectFit: 'cover',
                                     objectPosition: 'center center'
                                 }}/>
                        )}
                        <br/>
                        <label htmlFor="raised-button-file">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{textTransform: 'none', color: '#ffffff'}}
                                startIcon={<PhotoCamera/>}>
                                {t('upload_profile_picture')}
                            </Button>
                        </label>
                    </div>
                    <br/>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="displayName"
                        label={t('display_name')}
                        name="displayName"
                        autoComplete="displayName"
                        autoFocus
                        inputProps={{
                            maxLength: 60,
                        }}
                        value={values.displayName}
                        onChange={handleChange}
                        error={!!errors.displayName}
                        helperText={errors.displayName}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="bio"
                        label={t('bio')}
                        type="text"
                        id="bio"
                        multiline
                        rows={4}
                        inputProps={{
                            maxLength: 300,
                        }}
                        autoComplete="current-bio"
                        value={values.bio}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions sx={{pb: 2}}>

                    <Button onClick={handleClose}
                            sx={{pl: 3, pr: 3, pt: '10px', pb: '10px', textTransform: 'none'}}>{t('cancel')}</Button>
                    <Button onClick={handleSave}
                            disabled={!isSaveButtonEnabled}
                            sx={{pl: 3, pr: 3, pt: '10px', pb: '10px', textTransform: 'none', color: '#ffffff'}}
                            variant="contained">{t('save')}</Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}

export default EditProfileModal;
