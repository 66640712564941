import React, {useEffect, useState} from "react";
import {Backdrop, IconButton, List, ListItemButton, ListItemText} from "@mui/material";
import {Close, Error} from "@mui/icons-material";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";
import miscService from "../../../services/api/misc/miscService";
import {HashLoader} from "react-spinners";

const Content = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const Container = styled.div`
    max-width: 35rem;
    width: 20rem;
    background-color: ${colors.searchBackground};
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 4px;
    margin: auto;

    @media (min-width: 768px) {
        width: 30rem;
    }

    @media (min-width: 1024px) {
        width: 35rem
    }

    @media (min-width: 1280px) {
        width: 35rem;
    }
`;

const Centered = styled.div`
    display: grid;
    place-items: center;
    margin: 2rem;
`;

const SearchField = styled.input`
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 0.9rem 16px;
    background: ${colors.white};

    @media (max-width: 768px) {
        padding: 1.1rem 16px;
    }
`;

const Title = styled.h3`
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 6px;
    margin-bottom: 10px;
    color: ${colors.textColor};
`

const PropertyTitle = styled.h3`
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 10px;
    color: ${colors.textColor};
`

const BreedSelectorDialog = ({open, handleClose, onBreedSelected, misc}) => {
    const [showLoader, setShowLoader] = useState(false);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showSearchResults, setShowSearchResults] = useState(false);


    useEffect(() => {
        const performSearch = async () => {
            if (searchQuery.length < 3) {
                setShowSearchResults(false);
                return;
            }
            let loaderTimeout = setTimeout(() => setShowLoader(true), 600);
            try {
                const searchResponse = await miscService.searchBreeds(searchQuery);
                setSearchResults(searchResponse);
                setShowSearchResults(true);
            } catch (err) {
                console.log(err)
                setError(err);
            } finally {
                setShowLoader(false);
                clearTimeout(loaderTimeout);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            performSearch();
        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            setShowLoader(false);
        };
    }, [searchQuery]);

    return (
        <Backdrop
            sx={{
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <Content>
                <IconButton
                    onClick={() => {
                        setSearchQuery("")
                        handleClose()
                    }
                    }
                    sx={{color: "#fff", bgcolor: "transparent", alignSelf: "end"}}
                >
                    <Close/>
                </IconButton>
                <Container>
                    <Title>{t('breed_selector_title')}</Title>
                    <SearchField
                        autoFocus
                        placeholder={t('search_hint')}
                        value={searchQuery}
                        onChange={(event) => setSearchQuery(event.target.value)}
                    />

                    {showLoader ? (
                        <Centered>
                            <HashLoader color={colors.secondaryTextColor} size={28}/>
                        </Centered>
                    ) : error ? (
                        <Centered>
                            <Error sx={{color: colors.textColor}}/>
                            <p style={{color: `${colors.textColor}`}}>{t('general_error')}</p>
                        </Centered>
                    ) : (
                        <div>
                            <PropertyTitle>
                                {showSearchResults ?
                                    (searchResults.length > 0 ? t('search_results') : "") :
                                    t('unknown_breeds')}
                            </PropertyTitle>
                            <List sx={{
                                borderRadius: 2,
                                width: '100%',
                                overflow: 'auto',
                                maxHeight: 400,
                                bgcolor: 'background.paper'
                            }}>
                                {
                                    showSearchResults ? (
                                        searchResults.length > 0 ? (
                                            searchResults.map((breed) => (
                                                <ListItemButton key={breed.id} onClick={() => {
                                                    onBreedSelected(breed)
                                                    handleClose()
                                                }}>
                                                    <ListItemText sx={{color: `${colors.textColor}`}}>
                                                        {`${t(breed.localizationKey)}, ${t(breed.species.localizationKey)}`}
                                                    </ListItemText>
                                                </ListItemButton>
                                            ))
                                        ) : (
                                            // Display "No results" message
                                            <Centered><p style={{color: `${colors.textColor}`}}>{t('no_results')}</p>
                                            </Centered>
                                        )
                                    ) : (
                                        misc.unknownBreeds.map((unknownBreed) => (
                                            <ListItemButton key={unknownBreed.id} onClick={() => {
                                                onBreedSelected(unknownBreed)
                                                handleClose()
                                            }}>
                                                <ListItemText sx={{color: `${colors.textColor}`}}>
                                                    {`${t(unknownBreed.localizationKey)}, ${t(unknownBreed.species.localizationKey)}`}
                                                </ListItemText>
                                            </ListItemButton>
                                        ))
                                    )
                                }
                            </List>
                        </div>
                    )}
                </Container>
            </Content>
        </Backdrop>
    );
};

export default BreedSelectorDialog;
