import React, {useEffect, useState} from 'react';
import {auth} from "../../../services/firebase/firebaseService";
import Unknown from "../../../assets/images/unknown.svg";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import MyProfileHeader from "../../../components/features/Profile/MyProfileHeader";
import styled from "styled-components";
import Report from "../../../components/features/Report/Report";
import Grid from "@mui/material/Grid";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import profileService from "../../../services/api/profile/profileService";
import {t} from "i18next";
import VerifyEmailAlert from "../../../components/common/Alerts/VerifyEmailAlert";
import reportService from "../../../services/api/report/reportService";
import EditProfileModal from "../../../components/features/Profile/EditProfileModal";
import {createTheme, Tab, Tabs, ThemeProvider} from "@mui/material";
import colors from "../../../assets/styles/colors";
import reservationService from "../../../services/api/reservation/reservationService";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import NoResults from "../../../components/features/NoResults/NoResults";
import {toast} from "react-toastify";


const ProfileHeaderWrapper = styled.section`
    padding-top: 2rem;
    padding-bottom: 2rem;
`

const Content = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const ReservationTitle = styled.h2`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 4rem 0 0;
`

const ReservationStatus = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
`

const AnimatedWrapper = styled.div`
    padding-bottom: 2rem;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const AddButton = styled.button`
    padding: 8px 30px;
    background-color: ${colors.primaryColor};
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;

    @media (max-width: 599px) {
        flex: unset;
        padding: 10px 10px;
        width: 100%;
    }

    &:hover {
        opacity: 0.6;
    }
`

const tabsTheme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '0.9rem',
                    fontWeight: '600',
                    textTransform: 'none',
                    '&.Mui-selected': {
                        color: `${colors.textColor}`,
                        fontWeight: '700',
                        fontSize: '1rem',
                    },
                },
            },
        },
    }
});

const ProfileTabs = ({section, handleSectionChange}) => (
    <ThemeProvider theme={tabsTheme}>
        <Tabs
            value={section}
            onChange={handleSectionChange}
            TabIndicatorProps={{
                style: {
                    width: '10%',
                    marginLeft: '8px',
                    background: `${colors.secondaryColor}`,
                }
            }}
        >
            <Tab label={t('section_reports')}/>
            <Tab label={t('section_reservations')}/>
        </Tabs>
    </ThemeProvider>
);

const ReportsList = ({reports, onNewReportClick}) => (
    reports.length > 0 ? (
        reports.map((report) => (
            <Grid key={report.id} item xs={12} sm={4} md={3} lg={3}>
                <Report thumbnailUrl={report.thumbnails[0]} reportId={report.id}/>
            </Grid>
        ))
    ) : (
        <Grid item xs={12}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2rem'
            }}>
                <br/>
                <NoResults title={t('no_reports_title')} description={t('no_reports_description')}/>
                <AddButton onClick={onNewReportClick}>{t('no_reports_create_report')}</AddButton>
            </div>
        </Grid>
    )
);

const ReservationsList = ({reservations, convertUnixTimestampToDate}) => {
    let navigate = useNavigate();

    return reservations.length > 0 ? (
        reservations.map((reservation) => (
            <Grid item xs={12} lg={12} key={reservation.reservationNumber}>
                <Paper
                    onClick={() => {
                        if (reservation.listingId != null) {
                            navigate(`/reservations/${reservation.reservationNumber}`);
                        }
                    }}
                    sx={{
                        width: '100%',
                        p: 2,
                        borderRadius: '10px',
                        backgroundColor: `${colors.secondaryBackground}`,
                        position: 'relative',
                        '&:hover': {opacity: 0.5},
                        cursor: reservation.listingId == null ? 'not-allowed' : 'pointer'
                    }}
                    elevation={0}
                >
                    <ReservationStatus style={{
                        position: 'absolute',
                        top: 9,
                        right: 9
                    }}>{convertUnixTimestampToDate(reservation.timestamp)}</ReservationStatus>
                    <ReservationTitle>{reservation.reservationNumber}</ReservationTitle>
                    {reservation.listingId == null ?
                        <ReservationStatus style={{color: 'red'}}>{t("listing_deleted")}</ReservationStatus> :
                        <ReservationStatus>{t(reservation.status.toLowerCase())}</ReservationStatus>
                    }
                </Paper>
            </Grid>
        ))
    ) : (
        <Grid item xs={12}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2rem'
            }}>
                <br/>
                <NoResults title={t('no_reservations_title')} description={t('no_reservations_subtitle')}/>
            </div>
        </Grid>
    );
};

const ProfileContent = ({section, reports, reservations, convertUnixTimestampToDate}) => {
    const navigate = useNavigate()

    switch (section) {
        case 0:
            return <ReportsList reports={reports} onNewReportClick={() => navigate('/new-report')}/>

        case 1:
            return <ReservationsList reservations={reservations}
                                     convertUnixTimestampToDate={convertUnixTimestampToDate}/>

        default:
            return <div/>
    }
}

const EmailVerificationAlert = ({show, email, onVerificationEmailResult}) => {
    if (show) {
        return <VerifyEmailAlert
            onResult={(result) => onVerificationEmailResult(result)}
            email={email}/>
    }

    return <div/>
}

const MyProfilePage = () => {
    const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
    const [reports, setReports] = useState(null);
    const [profile, setProfile] = useState(null);
    const [reservations, setReservations] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showEmailConfirmation, setShowEmailConfirmation] = React.useState(true);
    const [section, setSection] = React.useState(0)
    const getAvatar = () => profile?.avatarUrl || Unknown;

    const user = auth.currentUser

    useEffect(() => {
        setShowEmailConfirmation(!user.emailVerified)
    }, [user]);

    useEffect(() => {
        const fetchProfileData = async () => {
            setIsLoading(true);
            try {
                const profilePromise = profileService.getMyProfile();
                const reportsPromise = reportService.getMyReports(undefined, 1, 50);
                const reservationsPromises = Promise.all([
                    reservationService.getMyReservations("Submitted", undefined, 1, 50),
                    reservationService.getMyReservations("Finalized", undefined, 1, 50),
                    reservationService.getMyReservations("Canceled", undefined, 1, 50),
                ]);

                const [profileData, reportsData, reservationsData] = await Promise.all([
                    profilePromise,
                    reportsPromise,
                    reservationsPromises
                ]);

                const allReservations = reservationsData.flatMap(data => data.content)
                    .sort((a, b) => b.timestamp - a.timestamp);

                setProfile(profileData);
                setReports(reportsData.content);
                setReservations(allReservations);
            } catch (err) {
                console.error(err);
                toast.error(t(err.code || 'general_error'));
            }
            setIsLoading(false);
        };


        fetchProfileData();
    }, []);

    if (isLoading) {
        return <LokiLoader/>
    }

    const onVerificationEmailResult = (result) => {
        console.log(result)
        setShowEmailConfirmation(!result)
    }

    const handleSectionChange = (event, newValue) => {
        setSection(newValue);
    };

    function convertUnixTimestampToDate(unixTimestamp) {
        const date = dayjs.unix(unixTimestamp);
        const now = dayjs();

        if (date.isSame(now, 'day')) {
            return `${t('today')}, ${date.format('HH:mm')}`;
        } else {
            return date.format('YYYY-MM-DD');
        }
    }

    const handleProfileSaved = (updatedProfile) => {
        setProfile(updatedProfile);
        setIsProfileEditOpen(false);
    };

    return (
        <AnimatedWrapper>
            <NavBar/>
            <Container maxWidth="md">
                <ProfileHeaderWrapper>
                    <MyProfileHeader avatar={getAvatar()} displayName={profile.displayName}
                                     bio={profile.bio} onEditClick={() => setIsProfileEditOpen(true)}/>
                </ProfileHeaderWrapper>

                <EmailVerificationAlert
                    email={user.email}
                    onVerificationEmailResult={onVerificationEmailResult}
                    show={showEmailConfirmation}/>
                <ProfileTabs
                    section={section}
                    handleSectionChange={handleSectionChange}/>
                <Content>
                    <Grid container spacing={2}>
                        <ProfileContent
                            section={section}
                            reports={reports}
                            reservations={reservations}
                            convertUnixTimestampToDate={convertUnixTimestampToDate}/>
                    </Grid>
                </Content>
            </Container>
            <EditProfileModal
                profile={profile}
                open={isProfileEditOpen}
                setOpen={setIsProfileEditOpen}
                onSaved={handleProfileSaved}/>
        </AnimatedWrapper>
    )
        ;
};

export default MyProfilePage;